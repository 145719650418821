import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "@/views/PcYm/Home";

Vue.use(VueRouter)

const routes = [
  {path: '/', name: 'Home', component: Home},
  {path: '/SjTpApi',name:'SjTpApi',meta:{name:'随机图片'},component:() => import('../views/SjTpApi.vue')},
  {path: '/apiVue',name:'ApiVue',meta:{name:'Api聚合'},component:() => import('../views/ApiVue.vue')},
  {path: '/ApiXq',name:'ApiXq',meta:{name:'Api详情'},component:() => import('../views/ApiXq.vue')},
  {path: '/mHome',name:'mHome',meta:{name:'首页'},component:() => import('../views/MbYm/mHome.vue')},
  {path: '/sixdxq',name:'sixdxq',meta:{name:'60秒看世界详情'},component:() => import('../views/PcYm/sixdxq.vue')},
  {path: '/mSixXq',name:'mSixXq',meta:{name:'60秒看世界详情'},component:() => import('../views/MbYm/mSixXq.vue')},
  {path: '/mfBz',name:'MfBz',meta:{name:'免费壁纸'},component:() => import('../views/PcYm/MfBz.vue')},
  {path: '/mMfBz',name:'mMfBz',meta:{name:'免费壁纸'},component:() => import('../views/MbYm/mMfBz.vue')},
  {path: '/djVideo',name:'djVideo',meta:{name:'短剧'},component:() => import('../views/DjVideo.vue')},
  {path: '/DjVideoEs',name:'DjVideoEs',meta:{name:'短剧'},component:() => import('../views/DjVideoEs.vue')},
  {path: '/PcLT',name:'PcLT',meta:{name:'博客'},component:() => import('../views/PcYm/PCLT.vue')},
  {path: '/mLT',name:'mLT',meta:{name:'博客'},component:() => import('../views/MbYm/mLT.vue')},
  {path: '/Ly',name:'Ly',meta:{name:'留言'},component:() => import('../views/Lyb.vue')},
  {path: '/dszz',name:'dszz',meta:{name:'打赏'},component:() => import('../views/DaShang.vue')},

]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
