<template>
  <el-carousel :interval="4000" type="card" height="300px" style="width: 74%">
    <el-carousel-item v-for="item in urls" :key="item.id" >
      <img :src="item.urls" style="object-fit: scale-down;height: 100%;width: 100%" @click="openURl(item.urls)" >
    </el-carousel-item>
  </el-carousel>
</template>
<script>
export default {
  name: "Zmd",
  data(){
    return{
      urls:[
        {id:1,urls:"https://www.wudada.online/dmImage/002826GbHNc.jpg"},
        {id:2,urls:"https://www.wudada.online/dmImage/b89bb3d1f0a32da75fcde1d5836de140.jpg"},
        {id:3,urls:"https://www.wudada.online/dmImage/ff7e4889244b9b20985e077b6b65ebbb.jpg"},
        {id:4,urls:"https://www.wudada.online/广告位.png"},
        {id:5,urls:"https://www.wudada.online/广告位.png"},
        {id:6,urls:"https://www.wudada.online/dmImage/ff7e4889244b9b20985e077b6b65ebbb.jpg"},
      ]
    }
  },
  methods:{
    openURl(url){
      window.open(url)
    }
  }
}
</script>

<style scoped>
/*.el-carousel__item{*/
/*  color: #475669;*/
/*  opacity: 0.75;*/
/*  line-height: 300px;*/
/*  margin: 0;*/
/*}*/

/*.el-carousel__item:nth-child(2n) {*/
/*  background-color: #99a9bf;*/
/*}*/

/*.el-carousel__item:nth-child(2n+1) {*/
/*  background-color: #d3dce6;*/
/*}*/
</style>
