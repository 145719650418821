<template>
  <el-row type="flex" class="row-bg" justify="space-around" >
    <el-col :span="7">
      <div class="grid-content bg-purple" style="display: flex;justify-content: flex-end">
        <a @click="$router.push('/')" class="grid-content bg-purple" style="font-size: xx-large;font-weight: bold;text-decoration: none;color: #acdaff;cursor: pointer"> Wdd · online(个人测试应用网站)</a>
      </div>
    </el-col>
    <el-col :span="13">
      <div class="grid-content bg-purple-light" style="color: black;display: flex;justify-content: flex-end">
        <div style="margin-right: 10px;font-weight: bold;font-size: small;">
          <a class="hover-effect" @click="$router.push('/')">首页</a>
        </div>
        <div style="margin-right: 10px;font-weight: bold;font-size: small;">
          <a class="hover-effect" @click="$router.push('/apiVue')">API聚合</a>
        </div>
        <div style="margin-right: 10px;font-weight: bold;font-size: small;">
          <a class="hover-effect" @click="$router.push('/mfBz')" style="text-decoration: none;">免费壁纸</a>
        </div>
        <div style="margin-right: 10px;font-weight: bold;font-size: small;">
          <a class="hover-effect" @click="$router.push('/PcLT')">博客</a>
        </div>
        <div style="margin-right: 10px;font-weight: bold;font-size: small;">
          <a class="hover-effect" @click="$router.push('/Ly')">留言</a>
        </div>
      </div>
    </el-col>
    <el-col :span="4">
      <div class="grid-content bg-purple" style="justify-content: flex-start">
        <img src="https://www.wudada.online/dmImage/151727qj9IZ.jpg" style="width: 50px;height: 50px;border-radius: 50%;object-fit: cover;" >
      </div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "PcDhl"
}
</script>

<style scoped>
.hover-effect {
  color: #999999;
  text-decoration: none;
  transition: color,font-size 0.3s ease;

}

.hover-effect:hover {
  color: #333;
  font-size: larger;
  cursor: pointer

}
.el-row{
  display:flex;
  flex-wrap: wrap;
}

.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

</style>
