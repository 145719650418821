<template>
  <div>
    <el-container>
      <el-header>
        <pc-dhl></pc-dhl>
      </el-header>

      <el-main>
        <div style="display: flex;justify-content: center;text-align: center">
          <zmd></zmd>
        </div>

        <div style="display: flex;justify-content: center;text-align: center">
          <div style="width: 50%;min-height: 800px;margin-right: 1%">
            <div style="height: 150px;background-color: white;display: flex;justify-content: flex-start;box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);border-radius: 5px">
              <div style="height: 100%;width: 30%">
                <el-image :src="image" style="height:100%;display: block;border-radius: 5px"></el-image>
              </div>
              <div class="bsDiv" style="height:100%;width: 70%;margin: auto;text-align: left">
                <div style="height: 20px;margin: 10px;width: 100%;display: flex;flex-wrap: wrap">
                  <span style="font-weight: bold;display: block;width: 88%">每日六十秒看世界</span>
                  <router-link to="/sixdxq" class="blinking-text-new" style="font-size: small;width: 10%;display: block;margin-right: 1%;font-weight: bold;color: red;text-shadow: -1px -1px 0 #fff, 1px 1px 0 #333;font-style:italic">new!!!</router-link>
                </div>
                <div style="height: 15px;margin: 10px;" v-for="(val,key) in sixdDay" v-if="key < 4">
                  <router-link to="/sixdxq"  style="display:block;white-space: nowrap;max-width: 50em;text-overflow: ellipsis;overflow: hidden;color: black;text-decoration: none;">
                    {{ val.content }}
                  </router-link>
                </div>
              </div>
            </div>
            <h3 style="margin-top: 10px;font-weight: bold;color: #acdaff;box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);">导航列表</h3>
            <span style="font-size: xx-small;color: #acdaff">（可<router-link to="/Ly">留言</router-link>提供导航！）</span>
            <div style="text-align: right">
              <transition name="el-fade-in-linear">
                <el-input v-show="show2" class="transition-box" v-model="name" placeholder="输入关键字搜索"></el-input>
              </transition>
              <el-button icon="el-icon-search" circle  @click="showT"></el-button>
            </div>

            <div v-for="items in DhType">
              <div style="display:flex;flex-wrap: wrap">
                <div style="margin-top: 10px;text-align: left;width: 50%;">
                  <li :class="items.icon" style="font-size: x-large;color: #acdaff"></li>
                  <span href="#" style="color:#000;font-weight: bold;text-decoration: none;">{{ items.name }}</span>
                </div>
              </div>

              <div>
                <el-row :gutter="20">
                  <el-col :span="8" v-for="item in DhList" :key="item.id" v-if="item.type == items.id">
                    <div class="grid-content bg-purple dhlb" style="display: flex;" @click="tzurl(item.url)" >
                      <div style="width: 30%;" :key="item.image == null " v-if="item.image == null">
                        <img src="https://www.wudada.online/favicon.ico" width="50" height="50" style="border-radius: 50px" >
                      </div>
                      <div style="width: 30%;" :key="item.image == null" v-if="item.image != null">
                        <img :src="item.image" width="50" height="50" style="border-radius: 50px" >
                      </div>
                      <div style="width: 70%;line-height: 30px;text-align: left">
                        <span style="text-decoration: none;text-align: left">{{item.name}}</span>
                        <br>
                        <span style="font-size: xx-small">{{item.content}}</span>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
          <div style="width: 20%;background-color: white;box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);border-radius: 5px">
            <el-calendar v-model="value">
            </el-calendar>
            <div style="font-weight: bold;margin-top: 10px">历史上的今天</div>
            <div style="margin: 10px;" v-for="(val,key) in lsDay">
                  <span style="display:block;text-align: left;" v-show="key < 10">
                    {{val.id}}.{{val.date}}-{{ val.title }}
                  </span>
            </div>

          </div>
        </div>

      </el-main>

<!--      <el-footer>-->
<!--        <div>-->
<!--          友链-->
<!--        </div>-->
<!--      </el-footer>-->

    </el-container>


  </div>
</template>

<script>
import request from "@/utils/request";
import PcDhl from "@/components/PcDhl";
import Zmd from "@/components/Zmd";
import axios from "axios";

export default {

  name: "Home",
  components: {Zmd, PcDhl},
  data(){
    return{
      show2: false,
      name:"",
      nameT:"",
      cover:"contain",
      // sixdDay:[],
      DhList:[],
      DhType:[],
      sixdDay:JSON.parse(localStorage.getItem('wdd_sixdDay')) || [],
      image: JSON.parse(localStorage.getItem('wdd_image')).data || "",
      lsDay: JSON.parse(localStorage.getItem('wdd_lsDay')) || [],
    }
  },
  watch: {
    name: function(newVal, oldVal) {
      this.load()
    }
  },

  created() {
    this.load()
    window.addEventListener('resize', this.handleWindowResize); // 监听窗口大小变化事件
    this.handleWindowResize(); // 初始加载时进行一次设备类型判断

    // 注册组件销毁时的回调，移除事件监听器
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('resize', this.handleWindowResize);
    });
    // 在页面加载时检查localStorage是否有缓存数据，如果没有或已过期则请求数据
    const cachedData = JSON.parse(localStorage.getItem('wdd_lsDay'));
    const sixdDayS = JSON.parse(localStorage.getItem('wdd_sixdDay'));
    const images = JSON.parse(localStorage.getItem('wdd_image'));
    if (!cachedData || new Date().getTime() >= cachedData.expirationTime) {
      this.LSDay();
    } else {
      this.lsDay = cachedData.data;
    }
    if (!sixdDayS || new Date().getTime() >= sixdDayS.expirationTime){
      this.SixDay();
    }else {
      this.sixdDay = sixdDayS.data;
    }
    if (!images || new Date().getTime() >= images.expirationTime){
      this.SixDay();
    }else {
      this.image = images.data;
    }

  },


  mounted() {

    // document.title = 'API接口-接口大全';
    document.title = 'Wdd·Online(个人测试应用网站)';

  },


  methods:{

    tzurl(url){
      window.open(url)
    },

    showT(){
      this.show2 = !this.show2
      this.name = ""
    },

    load(){
      this.$request.get('/Web/DhList',{
        params:{
          name:this.name
        }
      }).then(res => {
          if (res.data != null){
            this.DhList = res.data.data
          }
      })
      this.$request.get('/Web/DhType',{
        params:{
          name:this.nameT
        }
      }).then(res => {
        if (res.data != null){
          this.DhType = res.data.data
        }
      })
    },


    handleWindowResize() {

      const userAgent = navigator.userAgent.toLowerCase();
      const isMobile = /iphone|ipad|ipod|android|blackberry|opera mini|iemobile|mobile/i.test(userAgent);

      let targetPath = '/'; // 默认为 PC 首页

      if (window.innerWidth < 768 || isMobile) {
        targetPath = '/mHome';
      }

      if (this.$route.path !== targetPath) {
        this.$router.replace(targetPath);
      }
    },
    SixDay(){

      // 发送请求获取数据
      request.get('https://www.wudada.online/Web/Api/ScD').then(res => {
            console.log(res);

            this.sixdDay = res.data.data.content
            this.image = res.data.data.imageurl
        // 计算第二天的日期
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        tomorrow.setHours(0, 0, 0, 0);

        // 将数据存储在localStorage中，并设置过期时间戳为第二天的时间
        const expirationTime = tomorrow.getTime();
        const sixdDayS = {
          data: res.data.data.content,
          expirationTime: expirationTime
        };
        localStorage.setItem('wdd_sixdDay', JSON.stringify(sixdDayS));

        const images = {
          data: res.data.data.imageurl,
          expirationTime: expirationTime
        };
        localStorage.setItem('wdd_image', JSON.stringify(images));

        // 启动定时器，在第二天的时间清除缓存数据
        const msUntilTomorrow = tomorrow.getTime() - new Date().getTime();
        setTimeout(() => {
          localStorage.removeItem('wdd_sixdDay');
          localStorage.removeItem('wdd_image');
        }, msUntilTomorrow);
            window.location.reload();
      }
      ).catch(error => {
        console.error('Error fetching lsDay:', error);
      });

    },
    LSDay() {
      // 发送请求获取数据
      this.$request.get('/Api/ScLsDay?month=&&day=').then(res => {
            this.lsDay = res.data.data;

            // 计算第二天的日期
            const tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            tomorrow.setHours(0, 0, 0, 0);

            // 将数据存储在localStorage中，并设置过期时间戳为第二天的时间
            const expirationTime = tomorrow.getTime();
            const cachedData = {
              data: res.data.data,
              expirationTime: expirationTime
            };
            localStorage.setItem('wdd_lsDay', JSON.stringify(cachedData));

            // 启动定时器，在第二天的时间清除缓存数据
            const msUntilTomorrow = tomorrow.getTime() - new Date().getTime();
            setTimeout(() => {
              localStorage.removeItem('wdd_lsDay');
            }, msUntilTomorrow);
          }).catch(error => {
            console.error('Error fetching lsDay:', error);
          });
    }
  }

}
</script>

<style scoped>
.transition-box {
  /*margin-bottom: 10px;*/
  width: 200px;
  border-radius: 4px;
  text-align: center;
  color: #fff;
  /*padding: 40px 20px;*/
  box-sizing: border-box;
  margin-right: 10px;
  margin-left: 10px;
}

@keyframes blinking {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}
.blinking-text-new {
  animation: blinking 1.5s infinite;
}
.el-row{
  display:flex;
  flex-wrap: wrap;
}
.el-col {
  border-radius: 4px;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.el-header, .el-footer {
  background-color: white;
  color: #333;
  /*text-align: center;*/
  line-height: 60px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)
}

body > .el-container {
  margin-bottom: 40px;
}
.bsDiv:hover{
  transition: background-color 0.3s ease;
  background-color: #d9d9d9;
  border-radius: 5px;
}


.dhlb{
  background-color: white;
  height: 60px;
  line-height: 60px;
  margin-top: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
}
.dhlb:hover{
  background-color: rgba(200,240,240,0.17);
  box-shadow: 0 2px 4px rgba(0, 0, 0, .25), 0 0 6px rgba(0, 0, 0, .16);
  cursor: pointer
}

/deep/.el-calendar-table .el-calendar-day{
  height: 38px; /* 调整高度 */
}
</style>

